<template>
  <v-expansion-panels :value="myPanel" multiple>
    <v-expansion-panel>
      <v-expansion-panel-header
        >{{ $t("searchPanel") }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col>
            <v-combobox
              v-model="patientName"
              :items="patients"
              clearable
              :label="$t('patients.patientName')"
              item-text="patientDisplayName"
              item-value="patientDisplayName"
              :return-object="false"
              hide-details
              outlined
              dense
            >
            </v-combobox>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-dialog
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fromDate"
                  hide-details
                  outlined
                  dense
                  :label="$t('fromDate')"
                  persistent-hint
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fromDate"
                no-title
                @input="menu1 = false"
              ></v-date-picker>
            </v-dialog>
          </v-col>
          <v-col>
            <v-dialog
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  hide-details
                  outlined
                  dense
                  v-model="toDate"
                  :label="$t('toDate')"
                  persistent-hint
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="toDate"
                no-title
                @input="menu2 = false"
              ></v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="auto">
            <v-btn color="primary" @click="filterChange()">
              <v-icon>
                mdi-refresh
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  props: ["patients", "panel"],
  data() {
    return {
      //panel: [0],
      menu1: null,
      menu2: null,
      patientName: "",
      fromDate: "",
      toDate: "",
      status: null,
      //  myPanel: [],
      filterItem: {
        // doctorName: "",
      },
    };
  },
  created() {
    this.fromDate = this.dateNow;
    this.toDate = this.dateNow;
    //if (this.panel) this.myPanel.push(this.panel);
  },
  watch: {
    patientName() {
      this.filterChange();
    },
  },
  computed: {
    myPanel() {
      return this.panel ? [0] : [];
    },
  },
  methods: {
    // patientNameKeyPress(e) {
    //   if (e.key == "Enter") {
    //     if (this.patientName) {
    //       this.filterChange();
    //     }
    //   }
    // },
    filterChange() {
      var filterItem = {
        patientName: this.patientName,
        fromDate: this.fromDate,
        toDate: this.toDate,
      };
      this.$emit("filterChange", filterItem);
    },
  },
};
</script>

<style></style>
